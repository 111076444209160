import { initializeApp } from "firebase/app";
import {getFirestore} from "firebase/firestore"
import {getAuth} from "firebase/auth";
import {getStorage} from "firebase/storage"
const firebaseConfig = {
  apiKey: "AIzaSyALPI8LVoh7t6pjax-K93xoGSeaoiRBGLU",
  authDomain: "enterprisesnk-17d18.firebaseapp.com",
  projectId: "enterprisesnk-17d18",
  storageBucket: "enterprisesnk-17d18.appspot.com",
  messagingSenderId: "375841665065",
  appId: "1:375841665065:web:cb61cd416580c3ca54ac38"
};
export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);