import { db } from "../../firestore-config";
import { collection,getDocs,getDoc,addDoc,updateDoc,deleteDoc,doc} from "firebase/firestore";
import { firestore } from "firebase-admin";
import { Product, User } from "../models/company";
import { Category } from "../models/category";

const categoryCollectionRef = collection(db,"Category");
const productCollectionRef = collection(db,"Product");
const userCollectionRef = collection(db,"User");
const userService = {
    register: (user:User) =>{
        return addDoc(userCollectionRef,user)
    },
    manage: (id:any,user:User) =>{
    const userinfo = doc(userCollectionRef,"User",id);
    updateDoc(userinfo,{
        user 
    });
    return getDoc(userinfo);
    },
    delete: (id:any)=>{
        const userinfo = doc(userCollectionRef,"User",id);
        deleteDoc(userinfo);
    },
    users:()=>{
        return getDocs(userCollectionRef);
    }
}
const categoryService = {
    register: (category:Category) =>{
        return addDoc(categoryCollectionRef,category)
    },
    manage: (id:any,category:Category) =>{
    const categoryinfo = doc(categoryCollectionRef,"Category",id);
    updateDoc(categoryinfo,{
        category 
    });
    return getDoc(categoryinfo);
    },
    delete: (id:any)=>{
        const categoryinfo = doc(categoryCollectionRef,"Category",id);
        deleteDoc(categoryinfo);
    },
    categories:()=>{
        return getDocs(categoryCollectionRef);
    }
}
const productService = {
    register: (product:Product) =>{
        return addDoc(productCollectionRef,product)
    },
    manage: (id:any,product:Product) =>{
    const productinfo = doc(productCollectionRef,"Product",id);
    updateDoc(productinfo,{
        product 
    });
    return getDoc(productinfo);
    },
    delete: (id:any)=>{
        const productinfo = doc(productCollectionRef,"Product",id);
        deleteDoc(productinfo);
    },
    products:()=>{
        return getDocs(productCollectionRef);
    }
}
const firebaseAgent = {    
    userService,        
    categoryService,
    productService,
}
export default firebaseAgent;