import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
export default observer(function ResponsiveHeaderPage() {      
  const { commonStore,modalStore,userStore,wizardStore,companyStore} = useStore();
  const navigate = useNavigate();
  useEffect(() => {
    if (commonStore.token) {      
     userStore.getUser().finally(() => commonStore.setAppLoaded(true))		 
    } else {
      commonStore.setAppLoaded(true);
    }
  }, [])
    return (
        <section data-bs-version="5.1" className="menu bistro_menu1 cid-t3PcmFS2QD" id="menu1-0">
    <nav className="navbar navbar-dropdown navbar-expand-lg">
        <div className="menu_box container">
            <div className="navbar-brand d-flex d-lg-none">
                <span className="navbar-logo">
                    <a href="https://www.enterprisesnk.info/">
                        <img src="/assets/images/nkenterprises-1-100x49.webp" alt="N.K. Enterprises" />
                    </a>
                </span>                
            </div>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-bs-toggle="collapse" data-target="#navbarSupportedContent" data-bs-target="#navbarSupportedContent" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                <div className="hamburger">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <div className="navbar-brand d-none d-lg-flex">
                <span className="navbar-logo">
                    <a href="https://www.enterprisesnk.info/">
                        <img src="/assets/images/nkenterprises-1-100x49.webp" alt="N.K. Enterprises" />
                    </a>
                </span>                    
                </div>
                <ul className="navbar-nav nav-dropdown nav-right" data-app-modern-menu="true">
                <li className="nav-item" style={{width:'80px'}}><a className="nav-link link display-4" 
                style={{cursor:'pointer'}}
                onClick={()=>{
                        navigate("/");
                    }}
                    >Home</a></li>
                    <li className="nav-item" style={{width:'80px'}}><a className="nav-link link display-4"
                    onClick={()=>{                                                
                        navigate("/aboutus");
                    }} 
                    style={{cursor:'pointer'}}
                    >About Us</a></li>
                    <li className="nav-item" style={{width:'150px'}}><a className="nav-link link display-4"                    
                    style={{cursor:'pointer'}} onClick={()=>{
                        navigate("/#features1-2");
                    }}
                    >Our Collections</a></li>                    
                    <li className="nav-item" style={{width:'150px'}}>
                        <a className="nav-link link display-4"
                        onClick={()=>{
                        navigate("/terms");
                    }} style={{cursor:'pointer'}}
                    >Terms Conditions</a>                        
                        </li>
                    <li className="nav-item" style={{width:'150px'}}><a className="nav-link link display-4"                     
                    style={{cursor:'pointer'}} onClick={()=>{
                        navigate("/privacy");
                    }}
                    >Privacy Policy</a></li>
                    <li className="nav-item" style={{width:'150px'}}>                        
                        <a className="nav-link link display-4" onClick={()=>{
                        navigate("/refund");
                    }} style={{cursor:'pointer'}}        
                    >Refund Policy</a>
                    </li>
                    <li className="nav-item" style={{width:'100px'}}><a className="nav-link link display-4" onClick={()=>{
                        navigate("/contactus");
                    }} style={{cursor:'pointer'}}>Contact Us</a></li></ul>

                <div className="icons-menu-main">
                    <a className="iconfont-wrapper" href="#" target="_blank">
                        <span className="p-2 mbr-iconfont socicon-facebook socicon"></span>
                    </a>
                    <a className="iconfont-wrapper" href="#" target="_blank">
                        <span className="p-2 mbr-iconfont socicon-twitter socicon"></span>
                    </a>
                    <a className="iconfont-wrapper" href="#" target="_blank">
                        <span className="p-2 mbr-iconfont socicon-instagram socicon"></span>
                    </a>
                    <a className="iconfont-wrapper" href="#" target="_blank">
                        <span className="p-2 mbr-iconfont socicon-linkedin socicon"></span>
                    </a>
                </div>
                
                <div className="offcanvas_box">
                    <button className="btn_offcanvas" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
                        <div className="hamburger">
                            <span></span>
                            <span></span>
                        </div>
                    </button>
                    <div className="offcanvas offcanvas-end" style={{tabindex:'-1'}} id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                        <div className="offcanvas-header">
                            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div className="offcanvas-body">
                            
                            <div className="offcanvas_contact">
                                <h3 className="mbr-section-subtitle mbr-fonts-style display-5"><strong>N.K. Enterprises.</strong></h3>
                            </div>
                            <div className="icons-menu">
                                <a className="iconfont-wrapper" href="#" target="_blank">
                                    <span className="p-2 mbr-iconfont socicon-facebook socicon"></span>
                                    <p className="mbr-text mbr-fonts-style display-4">
                                        Facebook</p>
                                </a>
                                <a className="iconfont-wrapper" href="#" target="_blank">
                                    <span className="p-2 mbr-iconfont socicon-twitter socicon"></span>
                                    <p className="mbr-text mbr-fonts-style display-4">
                                        Twitter</p>
                                </a>
                                <a className="iconfont-wrapper" href="#" target="_blank">
                                    <span className="p-2 mbr-iconfont socicon-instagram socicon"></span>
                                    <p className="mbr-text mbr-fonts-style display-4">
                                        Instagram</p>
                                </a>
                                <a className="iconfont-wrapper" href="#" target="_blank">
                                    <span className="p-2 mbr-iconfont socicon-linkedin socicon"></span>
                                    <p className="mbr-text mbr-fonts-style display-4">
                                        Linkedin</p>
                                </a>
                            </div>
                            <p className="text_widget mb-0 mbr-fonts-style display-4"><a href="#" className="text-secondary">Shop No. 149, New Colony, Gurugram, Haryana - 122001
                                </a>
                                <br/><br/>
                                <a href="tel:918755116500" className="text-secondary">+918755116500</a>
                                <br/><br/>
                                <a href="mailto:niranjankumarr2024@gmail.com" className="text-secondary">niranjankumarr2024@gmail.com</a>
                            </p>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </nav>   
</section>
    )
})