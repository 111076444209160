import { createBrowserRouter, Navigate, RouteObject } from "react-router-dom";
import NotFound from "../../features/errors/NotFound";
import ServerError from "../../features/errors/ServerError";
import TestErrors from "../../features/errors/TestError";
import App from "../layout/App";
import ResponsiveTrendyHeaderPage from "../../features/home/Header/ResponsiveHeaderPage";
import ResponsiveTrendyFooterPage from "../../features/home/Footer/ResponsiveFooterPage";
import ResponsiveHeaderPage from "../../features/home/Header/ResponsiveHeaderPage";
import ResponsiveFooterPage from "../../features/home/Footer/ResponsiveFooterPage";
import ResponsiveCategoriesPage from "../../features/home/categories/ResponsiveCategoriesPage";
import PrivacyPolicyPage from "../../features/home/PrivacyPolicyPage";
import ResponsiveProductPage from "../../features/home/categories/ResponsiveProductPage";
import TermsConditionsPage from "../../features/home/TermsConditionsPage";
import RefundCancellationPolicyPage from "../../features/home/RefundCancellationPolicyPage";
import ResponsiveOrderPage from "../../features/home/categories/ResponsiveOrderPage";
import AboutUsPage from "../../features/home/AboutUsPage";
import ContactUsPage from "../../features/home/ContactUsPage";

export const routes: RouteObject[] = [                          
            {path: 'not-found', element: <><ResponsiveTrendyHeaderPage /><NotFound /><ResponsiveTrendyFooterPage /></>},    
             {path: '*', element: <Navigate replace to='/not-found' />},
    {
        path:'/',
        element: <App />,
        children: [
        ]
    },
    {
        path:'/aboutus',
        element:<><ResponsiveTrendyHeaderPage /><AboutUsPage /><ContactUsPage /><ResponsiveTrendyFooterPage /></>
    },
    {
        path:'/contactus',
        element:<><ResponsiveTrendyHeaderPage /><ContactUsPage /><ResponsiveTrendyFooterPage /></>
    },
    {
        path:'/privacy',
        element:<><ResponsiveTrendyHeaderPage /><PrivacyPolicyPage /><ContactUsPage /><ResponsiveTrendyFooterPage /></>
    },
    {
        path:'/terms',
        element:<><ResponsiveTrendyHeaderPage /><TermsConditionsPage /><ContactUsPage /><ResponsiveTrendyFooterPage /></>
    },
    {
        path:'/refund',
        element:<><ResponsiveTrendyHeaderPage /><RefundCancellationPolicyPage /><ContactUsPage /><ResponsiveTrendyFooterPage /></>
    },
    {
        path:'/thankyou',
        element:<><ResponsiveTrendyHeaderPage /><ResponsiveOrderPage /><ContactUsPage /><ResponsiveTrendyFooterPage /></>
    },
    {
        path:'/product/:id',
        element:<><ResponsiveTrendyHeaderPage /><ResponsiveProductPage /><ContactUsPage /><ResponsiveTrendyFooterPage /></>
    }
]

export const router = createBrowserRouter(routes);